import "./Styles/main.scss";
import { Slick } from "@/pages/vendors/vendor.slick";
import { createAniView } from "@/pages/vendors/vendor.aniView";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { KnownCmsPageType } from "@/api";

$(function () {
    createAniView($(".aniview"));
    const slickBanner = new Slick($(".slides-banners"), {
        dots: true,
        arrows: false,
        autoplaySpeed: 5000,
    });

    slickBanner.slickPlay();
});

/* 产品详情页 用按钮控制图片左右滚动 */
$(async function () {
    if (cmsGlobal.Cms.IsCustomDomain && cmsGlobal.KnownPage === KnownCmsPageType.CmsProductDetail) {
        import(/* webpackChunkName: "imagePhotoswipe" */ "../../pages/modules/imagePhotoswipe");
    }
    const sliderFor = new Slick($(".slider-for"), {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        infinite: false,
        asNavFor: ".slider-nav",
    });
    sliderFor.slickPlay();
    const sliderNav = new Slick($(".slider-nav"), {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: ".slider-for",
        focusOnSelect: true,
        infinite: false,
    });

    $(document).on("click", ".left-right-arrows .next", function () {
        sliderNav.slickNext();
    });
    $(document).on("click", ".left-right-arrows .prev", function () {
        sliderNav.slickPrev();
    });

    const length = $(".slider-nav .slide-item").length;
    if (length > 4) {
        $(".left-right-arrows a").removeClass("disabled");
    } else {
        $(".left-right-arrows a").addClass("disabled");
    }
});
